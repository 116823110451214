import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/www/src/templates/mdx-page-layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "hug-a-tree"
    }}><a parentName="h2" {...{
        "href": "/lessons/hug-a-tree/"
      }}>{`Hug a Tree`}</a></h2>
    <p>{`Start off with 5 minutes of Hug a Tree meditation.`}</p>
    <h2 {...{
      "id": "footwork-stepping-left-stepping-right"
    }}>{`Footwork: Stepping Left, Stepping Right`}</h2>
    <p>{`Like `}<a parentName="p" {...{
        "href": "/lessons/shifting-weight/"
      }}>{`Sitting Left and Right`}</a>{` but now with stepping.`}</p>
    <h2 {...{
      "id": "horizontal-circles"
    }}>{`Horizontal Circles`}</h2>
    <p>{`Gently rotating your upper body to the left and right by loosening the lumbar spine.`}</p>
    <h2 {...{
      "id": "arms-wave-hands"
    }}>{`Arms: Wave Hands`}</h2>
    <p>{`Cross the arms in the middle, circle up and out and down to “wave”, straighten and re-curve into “gorilla arms”, then pivot back up to cross arms.`}</p>
    <h2 {...{
      "id": "posture-wave-hands-like-clouds"
    }}>{`Posture: Wave Hands Like Clouds`}</h2>
    <p>{`Putting all the above together into the taiji posture called Wave Hands Like Clouds.`}</p>
    <p><a parentName="p" {...{
        "href": "/lessons"
      }}>{`Back to Lessons`}</a></p>
    <p><a parentName="p" {...{
        "href": "/lessons/lesson-1/"
      }}>{`Back to Lesson 1`}</a></p>
    <p><a parentName="p" {...{
        "href": "/lessons/lesson-3/"
      }}>{`On to Lesson 3`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      